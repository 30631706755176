<template>
    <header class="VPNav no-sidebar" data-v-37134a4b="" data-v-eaf78fb0="">
                 <div class="VPNavBar" data-v-eaf78fb0="" data-v-3067a79b="">
                    <div class="container" data-v-3067a79b="">
                       <div class="VPNavBarTitle" data-v-3067a79b="" data-v-fb551c86="">
                          <a class="title" href="/" data-v-fb551c86="">
                             <img class="VPImage logo" src="../assets/aegis-logo.png" alt="" data-v-f32d7a01="">AegisProxy
                          </a>
                       </div>
                       <div class="content" data-v-3067a79b="">                      
                          <div class="VPNavBarSearch search" data-v-3067a79b="" style="--213c6eca:&quot;Ctrl&quot;;">
                             <div id="docsearch"></div>
                          </div>
                          <nav aria-labelledby="main-nav-aria-label" class="VPNavBarMenu menu" data-v-3067a79b="" data-v-8b562aed="">
                             <span id="main-nav-aria-label" class="visually-hidden" data-v-8b562aed="">Main Navigation</span>
                             <Modal>
								<template #opener>
									<a class="VPLink link VPNavBarMenuLink"  data-v-8b562aed data-v-4e9583e5 data-v-a4f62f22 >Get Aegis Proxy
                            		</a>
								</template>
								<template #main>
									<Purchase></Purchase>
								</template>
							 </Modal>
                      <a class="VPLink link VPNavBarMenuLink" data-v-8b562aed="" data-v-4e9583e5="" data-v-a4f62f22=""> blog</a>
                      <a class="VPLink link VPNavBarMenuLink" data-v-8b562aed="" data-v-4e9583e5="" data-v-a4f62f22=""> <router-link :to="{path:'/api/'}" >api</router-link></a>
                             <Modal v-if="!user">
								<template #opener>
									<a class="VPLink link VPNavBarMenuLink"  data-v-8b562aed="" data-v-4e9583e5="" data-v-a4f62f22="" >my account
                            		</a>
								</template>
								<template #main>
									<LoginForm></LoginForm>
								</template>
							 </Modal>
                      <div v-else style="display:flex">
                        <a class="VPLink link VPNavBarMenuLink" data-v-8b562aed="" data-v-4e9583e5="" data-v-a4f62f22=""> <router-link :to="{path:'/user/my-proxies'}" >userboard</router-link></a>
                      </div>                      
                      <a  v-if="user" class="VPLink link VPNavBarMenuLink" data-v-8b562aed="" data-v-4e9583e5="" data-v-a4f62f22="" @click="handleLogout">Log Out</a>
                             <!--]--><!--[--><!--]--><!--[--><!--]--><!--]-->
                          </nav>
                        </div>
                    </div>
                 </div>
              </header>
</template>

<script>
import { ref } from 'vue';
import LoginForm from '../components/LoginForm.vue';
import Purchase from '../components/Purchase.vue'
import Modal from '../components/Modal.vue';
import getUser from '@/composables/getUser'
import useLogout from '@/composables/useLogout';
import { useRouter } from 'vue-router'
export default {
   components:{LoginForm, Purchase, Modal},
   setup() {
      const {user} = getUser()

      const router = useRouter()
      const { error, logout, isPending } = useLogout();       
        const handleLogout = async () => {
            await logout();
            router.push({path: '/'});
        }
      const pricing = ref(null)
      return {user, handleLogout, pricing}
   }
}
</script>

<style>
header{

   height: 70px;
}
</style>