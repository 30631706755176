<template>
  <div style="padding-top:150px;display: flex;justify-content: center; align-items: left;flex-direction: column; width: fit-content; margin: auto;">
      <p style="font-size:xx-large;margin-bottom: 20px; font-weight: 600;">Control Panel</p>
      <div class="container">
      <div style="display:flex;flex-direction:column;justify-content:start">
      <div style="width: 250px">
          <p  style="font-size: 25px;font-weight: 600;margin-bottom:20px">Proxy Credentials</p>
          <div class="skeleton-box" style="display:flex; justify-content:space-between; align-items:center;color: grey;background-color: grey; border-radius: 5px;"><p style="font-weight:500">00.00.000.00:38343:2432g:T43f</p></div>
          <button class="skeleton-box" style="margin-top:10px">add port</button>
      </div>
      <div style="margin:80px 0px 0px 0px;width: 250px;">
          <p style="font-size: 25px;font-weight: 600;margin-bottom:20px">Ip Whitelist</p>
          <div class="skeleton-box" style="display:flex; justify-content:space-between;align-items:center;margin-bottom: 2px;font-weight: 500;;color: grey;background-color: grey; border-radius: 5px;"><p>00.00.000.00:38343</p></div>
          <div class="skeleton-box" style="background-color:grey; color: grey; width: max-content; height: max-content; border-radius: 5px;"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg></div>
      </div>
      </div>
      <div style="display:flex;flex-direction:column;align-items:center">
          <p style="font-size: 25px;font-weight: 600;text-align:center; margin-bottom: 20px;">Data Plan</p>
          <div class="skeleton-box" style="background-color:grey; height: 12rem;width: 12rem;border-radius:50%;"></div>
          <p class="skeleton-box" style="text-align:center; font-weight:600;margin: 15px 0px 15px 0px;color: grey;background-color: grey;border-radius: 5px;">0 GB used</p>
          <Modal>
                      <template #opener>
              <button>Purchase GB's</button>	
                      </template>
                      <template #main>
                          <Purchase></Purchase>
                      </template>
                  </Modal> 
          <button  class="skeleton-box" style="margin-top:10px">Purchase GB's</button>
          <div class="skeleton-box"></div>
      </div>
      
    </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
svg:hover {
  cursor:pointer;
  }
  button{
      border: 2px solid grey;
      border-radius: 20px;
      padding-left: 15px;   
      padding-right:15px;
      padding-bottom: 4px;
      font-weight: 600;
      color:grey;
      background-color:gray;
  }
  .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      border-radius: 10px; 
      padding: 50px 0px 50px 0px;
      width: 60vw;
      background-color: #2f2f2f;
      margin-bottom: 50px;
  }
  input:focus{
      outline: none;
  }
  .circle {
      border:20px solid rgb(94, 94, 101);
      width:200px;
      height:200px;
      border-radius: 50%;
  }
  @keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
  }
  
  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }
  
  div[role="progressbar"] {
    --size: 12rem;
    --fg: rgb(255, 196, 0);
    --bg: rgb(163, 163, 186);
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: 
      radial-gradient(closest-side, #242424 80%, transparent 0 99.9%, #242424 0),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
      ;
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
  }
  
  div[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
  }

  .skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: grey;
 }

  .skeleton-box::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
  90deg,
    rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
);
    transform: translateX(-100%);
    content: "";
    animation: shimmer 5s infinite;
  }
  
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }



</style>

