<template>
    <div class="card">
        <p style="font-size:xx-large; font-weight: 600; text-align: center;" >Order</p>
        <div style="display: flex; justify-content: space-between; align-items: center; width: 75px;font-weight:600;font-size: 18px;margin:-20px 0 -15px 0">
            <!--<p>stock : </p><p :style="{ color: stock.proxyAvailable !== 0 ? 'green' : 'red' }">{{ stock.proxyAvailable }}</p>-->
        </div>
        <div>
        <div style="display:flex; justify-content: space-between; font-size: 20px; width: 200px;">
            <p style="font-weight: 600;">Prox{{quantity > 1 ? "ies" : "y"}} : </p><input type="number" v-model="quantity" min="1" max="100">
        </div> 
        <div style="display:flex; justify-content: space-between; margin: 20px 0 -10px 0; font-size: 20px;width: 200px;">
            <p style="font-weight:600">{{duration}}{{durationQuantity > 1 ? "s" : ""}} :</p><input type="number" v-model="durationQuantity" min="1" max="100">
        </div> 
        </div>
        <div style="display:flex; justify-content: space-between; font-size: 20px; width: 200px; border-top: solid black 1px ; padding-top:10px">
            <p style="font-weight: 600;">Total : </p> <p style="font-weight: 600;"> {{ quantity * durationQuantity * price }} €</p>
        </div>
        <button @click="yes" :disabled="quantity * durationQuantity * price == 0" :class="{buttonDisabled: quantity * durationQuantity * price == 0}">checkout</button>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { db, functions, httpsCallable } from "@/firebase/config"
import "vue-simple-range-slider/css";
import getCollection from '@/composables/getCollection';
import getDocument from '@/composables/getDocument';
export default {
    props: {
        duration: String,
        price: Number,
    },
    setup(props) {
        const quantity = ref(1)
        const durationQuantity = ref(1)

        //const stock = getDocument("proxyInfo", "0KnNdoEdlLjEiThAUMJB")

        const createStripeCheckout = httpsCallable(functions, 'createStripeCheckoutUnlimited')
		const stripe = Stripe("pk_test_51LuGRwIrspSkXtrvBwTwyzrDxJ4bLiQv3IBcRMCba9eKtk756g9KSOEojSxk9FayshHUpj2xby27ywij8dnO6VsR00yl6gNYkn")
		const yes = async () => {
            console.log({quantity:quantity.value, duration:props.duration, durationQuantity:durationQuantity.value})
			const response = await createStripeCheckout({quantity:quantity.value, duration:props.duration, durationQuantity:durationQuantity.value, shared: false})
			const sessionId = response.data.id
			stripe.redirectToCheckout({
				sessionId: sessionId
			})		
		}
        return { yes, quantity, durationQuantity }
    }
}
</script>

<style scoped>
.card {
    width:300px;
    height: 350px; 
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content:space-around;
    align-items: center;
    background-color:#2f2f2f ;
    border: solid 1px transparent;
    border-radius: 15px;
}
.card::after {
    position: absolute;
    top: -4px; bottom: -4px;
    left: -4px; right: -4px;
    background: linear-gradient(
  180deg,
  hsl(0deg 86% 30%) 0%,
  hsl(8deg 86% 32%) 11%,
  hsl(13deg 87% 35%) 22%,
  hsl(17deg 88% 37%) 33%,
  hsl(21deg 90% 39%) 44%,
  hsl(24deg 92% 41%) 54%,
  hsl(27deg 94% 42%) 64%,
  hsl(30deg 96% 44%) 72%,
  hsl(32deg 98% 45%) 81%,
  hsl(35deg 99% 47%) 88%,
  hsl(37deg 100% 48%) 94%,
  hsl(39deg 100% 50%) 100%
);
    content: '';
    z-index: -1;
    border-radius: 15px;
}
p{
    color:white;
}
input{
    font-weight: 700;
    width:40px;
    background: transparent;
    border-radius: 5px;
    border: solid grey 2px;
    text-align: center;
    font-weight: 500;
    font-size:large;
    color:white;
    margin-right: 5px;
    
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:focus{
    outline: none;
}
button{
    border: 2px solid orange;
    border-radius: 20px;
    padding-left: 15px;
    padding-right:15px;
    padding-bottom: 4px;
    font-weight: 600;
    color:white;
}
.slider {
  /* overwrite slider styles */
  width: 200px;
}

.buttonDisabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.emptyStock{
    color:red;
}
</style>