import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../views/Contact.vue'
import PaymentSuccess from '../views/PaymentSuccess.vue'
import PaymentCancel from '../views/PaymentCancel.vue'
import UserProfile from '../views/UserProfile.vue'
import ApiDoc from '../views/ApiDoc.vue'
import AccountDetails from '@/views/UserSection/AccountDetails.vue'
import MySubscriptions from '@/views/UserSection/MySubscriptions.vue'
import MyProxies from '@/views/UserSection/MyProxies.vue';
import BillingHistory from '@/views/UserSection/BillingHistory.vue'

const routes = [
  {
    path:'/',
    name:'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path:'/payment-success',
    name:'PaymentSuccess',
    component:PaymentSuccess
  },
  {
    path:'/payment-cancel',
    name:'PaymentCancel',
    component:PaymentCancel
  },
  {
    path:'/user',
    name:'UserProfile',
    component:UserProfile,
    children: [
      {
        path: 'account-details',
        component: AccountDetails
      },
      {
        path: 'billing-history',
        component: BillingHistory
      },
      {
        path: 'my-proxies',
        component: MyProxies
      },
      {
        path: 'subscriptions',
        component: MySubscriptions
      }
    ]

  },
  {
    path:'/api',
    name:'ApiDoc',
    component:ApiDoc
  }
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
