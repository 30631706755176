<template>
<h1 style="font-size: 30px; font-weight: 600; margin:0px 0px 30px 20px;line-height: 38px;">Account Details</h1>
      <div style="margin-bottom: 30px;">
        <h2 style="font-size: 20px; font-weight: 600; margin-bottom: 20px;line-height: 38px;">Email Adress</h2>
        <div style="width: 400px; display: flex;justify-content: space-between;">
          <p v-if="userInfo" style="font-weight: 300;margin-left: 10px;">{{userInfo.email}}</p>
          <p v-else style="font-weight: 300;margin-left: 10px;width: 100px;"></p>
          <div style="width:fit-content; height: 40px; background: #D4B700; border-radius: 100px;padding: 6px 15px 5px 15px;color: black;font-weight: 500; ">Update Email</div>
        </div>
      </div>
      <div>
        <h2 style="font-size: 20px; font-weight: 600; margin-bottom: 20px;line-height: 38px;">Password</h2>
        <div style="width:400px; display: flex; justify-content: space-between; align-items: center;">
          <p style="margin-left: 10px;">******************</p> 
          <div style="width: fit-content; height: 40px; background:#BCBCBC; border-radius: 100px; padding: 6px 15px 5px 15px;color: black;font-weight: 500;">Change Password</div>
        </div>
      </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import getUser from '@/composables/getUser';
  import getUserInfo from '@/composables/getUserInfo';
  export default {
    name: 'AccountDetails',
    setup() {
      const userInfo = getUserInfo()
      return {
        userInfo
      };
    }
  };
  </script>
  
  <style scoped>

  </style>
  