<template>
  <div style="height: 70px;"> </div>
  <div style="height: 70px; background-color:;border-top: solid 1px #4d4d4d; display: flex;">
    <div style="height: 87vh; width:180px;background-color: #1B1B1B; padding-bottom: ;">
      <h3 class="userNavLink" style="margin-top: 30px;"><router-link :to="{path: 'account-details'}">Account Details</router-link></h3>
      <h3 class="userNavLink"><router-link :to="{path: 'my-proxies'}">My Proxies</router-link></h3>
      <h3 class="userNavLink"><router-link :to="{path: 'subscriptions'}">Subscriptions</router-link></h3>
      <h3 class="userNavLink"><router-link :to="{path: 'billing-history'}">Billing History</router-link></h3> 
    </div>
    <div style="padding: 30px 0px 0px 50px;">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import ControlPanel from '@/components/ControlPanel.vue';
import AccountDetails from '@/views/UserSection/AccountDetails.vue'
import MySubscriptions from '@/views/UserSection/MySubscriptions.vue'
import MyProxies from './UserSection/MyProxies.vue';
import BillingHistory from '@/views/UserSection/BillingHistory.vue'
export default {
    components: { ControlPanel, AccountDetails, MySubscriptions, MyProxies, BillingHistory }
}
</script>

<style>

.userNavLink {
  margin: 25px 0px 25px 15px;
  font-weight: 300;
}
</style>