<template>
  <div style="height: 71px; border-bottom: solid 1px #4d4d4d;;"> </div>
  <div style="display: flex;flex-direction: column; align-items: center;height: 370px;">
    <h1 style="margin:50px 0px 30px 0px; font-size: 30px; font-weight: 500;">Contact Us</h1>
    <h2 style="margin: 0px 0px 80px 0px;font-weight: 300; font-size: 20px;">let us know how we can help you</h2>
    <div style="display: flex; justify-content: space-between;width: 550px;">
      <div style="width: fit-content; height: fit-content; padding: 12px 25px 15px 25px ; background: #C2C2C2; border-radius: 100px; border: 1px black solid;font-weight: 500;color: black;">Read the FAQ</div> 
      <div style="width: fit-content; height: fit-content; padding: 12px 25px 15px 25px;;background: #D4B700; border-radius: 100px; border: 1px black solid; font-weight: 500;color: black;">Email contact@aegis-proxy.com</div>
    </div>
  </div>
  <div style="height: 500px;background-color: #151515;display: flex; justify-content: space-between;">
      <img style="width: 300px; height: auto;" src="../assets/Logo5.png" alt="">
      <div style="width: 500px; display: flex; flex-direction: column; align-items: center; margin-left: 100px;">
        <h1 style="font-size: 40px; font-weight: 600;margin: 60px 0px 60px 0px;">Let's Do Business</h1>
        <h2 style="font-weight: 300; margin: 0px 0px 40px 0px; text-align: center;">
          Looking to collaborate and drive growth together? 
 <br>
 <br>
Whether it's forging partnerships, business development ventures, or customized pricing and offers, we're here to make it happen.
        </h2>
        <h2 style="font-weight: 300; margin-bottom: 30px;"> Get in touch with us today : </h2>
        <div style="width: fit-content; height: fit-content; padding: 12px 25px 15px 25px;;background: #D4B700; border-radius: 100px; border: 1px black solid; font-weight: 500;color: black;">Email business@aegis-proxy.com</div>
      </div>
      <div style="display: flex; flex-direction: column; justify-content: end;align-items:end;">
        <img style="width: 340px; height: 400px;" src="../assets/Logo6.png" alt="">
      </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Contact',
  setup() {

    return {
    };
  }
}
  </script>
  
  <style scoped>
  /* Add your styles here */
  </style>
  