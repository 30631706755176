<template>
<div style="background-color:#2f2f2f; width: 51vw; margin: 10px; border-radius: 15px; padding:25px;">
	<p style="font-size: 30px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px; margin: auto; margin-bottom: 20px; color:white">
	Where  4G proxies come from</p>
	<p style="font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px; margin: auto;color:white;">
	Traditional 4g proxy providers source their 4g connexions from regular cellphone users.
    These users share their internet connexion to the provider, which acts as an intermidiary
	between the proxy client, and the cellphone connexion.
    <br>
    <br>
    <img src="../assets/diagramm.png" alt="" style="width:50vw ;border-radius:5px">
	</p>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>