<template>
    <div class="price-card">
        <div style="display: flex;flex-direction: column; align-items: center; margin-top: 10px;" :style="{'--my-color': myColor}">
            <p style="font-size:30px; font-weight: 600; margin-bottom: 20px; text-align:center; color: yellow;" :style="{color: myColor}"> {{duration}}</p>
            <p style="text-align:center;font-weight: 500;"> unlimited GB </p>
            <p style="text-align:center;font-weight: 500; margin-bottom: 10px;"> 1 port </p>
            <span class="material-symbols-outlined">all_inclusive</span>
        </div>
        <p style="font-size:x-large; font-weight: 500;">{{price}}€</p>
        <Modal v-if="user">
            <template #opener>
                <button style="margin-bottom: 15px; margin-top: 10px;">Purchase now</button>	
            </template>
            <template #main>
                <Purchase  :duration=duration :price=price ></Purchase>
            </template>
        </Modal>
        <Modal v-else>
            <template #opener>
                <button style="margin-bottom: 15px; margin-top: 10px;">Purchase now</button>	
            </template>
            <template #main>
                <LoginForm></LoginForm>
            </template>

        </Modal>
        </div>
</template>
        
<script>
import Modal from '@/components/Modal.vue';
import Purchase from '@/components/Purchase.vue';
import getUser from '@/composables/getUser'
import LoginForm from './LoginForm.vue';
export default {
    name: 'PriceCard',
    props: {
        myColor: String,
        duration: String,
        price: String,

    },
    components: {
    Modal,
    Purchase,
    LoginForm
},
    setup() {
        const {user} = getUser()
        return {user}
    }
}
</script>
        
<style scoped>
.price-card {
width:200px;
height: 300px; 
border: solid black 3px;
border-radius: 20px; 
background-color: #2f2f2f; 
margin: auto ;
margin-bottom:100px;
display: flex;
flex-direction: column;
justify-content:space-around;
align-items: center;
transition: transform 0.2s ease;
/*box-shadow: 0 0 4px
rgb(243, 175, 2);
*/
}

.price-card:hover{
    transform: scale(1.04);
    
}
button{
      border: 1px solid orange;
      border-radius: 20px;
      padding-left: 15px;
      padding-right:15px;
      padding-bottom: 4px;
      font-weight: 600;
      color:white;
  }
  .material-symbols-outlined {
    color: grey;
    font-size: 40px;
    transition: 0.3s;
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48,

}

/*.price-card:hover .material-symbols-outlined {
    filter: drop-shadow(0 0 3px rgba(255, 255, 0, 1)) drop-shadow(0 0 6px rgba(255, 255, 0, 1));
  color: yellow;
}*/
.price-card:hover .material-symbols-outlined {
    color: var(--my-color);
}
</style>