<template>
  <div style=" display: inline;width:max-content" @click="showModal = true">
    <opener>
        <slot name="opener"></slot>
    </opener>
    </div>
    <Teleport to="body">
        <transition name="fade" appear>
  <div class="modal-overlay" v-if="showModal" @click="showModal = false"></div>
 </transition>
 <transition name="slide" appear>
    <div class="modal" v-if="showModal">
        <main>
        <slot name="main"></slot>
    </main>
    </div>
</transition>
    </Teleport>
</template>

<script>
import { ref } from 'vue';

export default {
    setup() {
        const showModal = ref(false)
        return {showModal}
    }
}
</script>

<style scoped>
.modal-overlay {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 z-index: 98;
 background-color: rgba(0, 0, 0, 0.6);
}

.modal {
 position: fixed;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 99;
}

.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
 transition: opacity 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
 opacity: 0;
}
</style>