<template>
  <div class="Layout" data-v-37134a4b="">
              <span tabindex="-1" data-v-b64e7837=""></span><a href="#VPContent" class="VPSkipLink visually-hidden" data-v-b64e7837=""> Skip to content </a><!--]--><!---->
              <!----><!---->
              <div class="VPContent is-home" id="VPContent" data-v-37134a4b="" data-v-acccd989="">
                 <div class="VPHome" data-v-acccd989="" data-v-ca9b87e3="">
                    <!--[--><!--]-->
                    <div class="VPHero has-image VPHomeHero" data-v-ca9b87e3="" data-v-b902bbd7="">
                       <div class="container" data-v-b902bbd7="">
                          <div class="main" data-v-b902bbd7="">
                             <h1 class="name" data-v-b902bbd7=""><span class="clip" data-v-b902bbd7="">Aegis</span></h1>
                             <p class="text" data-v-b902bbd7="">4G Proxies <br>Unlimited Data</p>
                             <p class="tagline" data-v-b902bbd7="">Unlimited bandwidth, blazing fast,  powerful API. Your future favorite proxy supplier</p>
                             <div class="actions" data-v-b902bbd7="">
                                <!--[-->
                                <Modal>
									<template #opener>
										<div class="action" data-v-b902bbd7=""><a class="VPButton medium brand" data-v-b902bbd7 data-v-d788bc63>Get Started</a></div>
									</template>
									<template #main>
										<LoginForm></LoginForm>
									</template>
								</Modal>
                                <div class="action" data-v-b902bbd7=""><a class="VPButton medium alt"  data-v-b902bbd7="" data-v-d788bc63=""><router-link :to="{path: '/contact'}"></router-link> Contact info</a></div>
                                <div class="action" data-v-b902bbd7=""><a class="VPButton medium alt" href="" target="_blank" rel="noreferrer" data-v-b902bbd7="" data-v-d788bc63="">Documentation</a></div>
                                <!--]-->
                             </div>
                          </div>
                          <div class="image" data-v-b902bbd7="">
                             <div class="image-container" data-v-b902bbd7="">
                                <Logo></Logo>
                                <!--[--><!--]-->
                             </div>
                          </div>
                       </div>
                    </div>
                    <!-- This div is for the bullet points-->
                    <div>
                     <div style="width: 100%;height: 300px; background-color:#151515 ; display: flex; justify-content: space-between; align-items: center;">
                     <div style="width: 500px; margin-left: 150px;">                     
                        <h2 style="font-size: 40px; font-weight: 600; margin-bottom: 30px;line-height: 38px;">Dedicated Devices for Unrivaled Stability</h2>
                        <div>
                           <p style="display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 5px;">Leave behind the frustrations of sharing your proxy with others. With Aegis Proxy, you get exclusive access to dedicated devices, ensuring consistently high speeds and the utmost reliability. No more slowdowns caused by multiple users – it's your private gateway to the internet.</p>
                        </div>
                     </div>
                     <img src="../assets/phonecolored.png " alt=""  style="width: 200px;height: 200px;margin-right: 200px;">
                     </div>
                     <div style="width: 100%;height: 300px; background-color:#242424 ; display: flex; justify-content: space-between; align-items: center;">
                     <img src="../assets/medalcolored.png " alt=""  style="width: 200px;height: 200px;margin-left: 150px;">
                     <div style="width: 500px; margin-right: 150px;">                     
                        <h2 style="font-size: 40px; font-weight: 600; margin-bottom: 30px;line-height: 38px;">Clean IP Pools with High Trust Scores</h2>
                        <div>
                           <p style="display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 5px;">Say goodbye to the worries of getting blocked or flagged. Our 4G proxies are equipped with French IP addresses boasting impressive trust scores. We've taken every measure to ensure your online activities are seamless, so you can focus on your work.</p>
                        </div>
                     </div>
                     </div>
                     <div style="width: 100%;height: 300px; background-color:#151515 ; display: flex; justify-content: space-between; align-items: center;">
                     <div style="width: 500px; margin-left: 150px;">                     
                        <h2 style="font-size: 40px; font-weight: 600; margin-bottom: 30px;line-height: 38px;">Powerful API for Instant IP Resets</h2>
                        <div>
                           <p style="display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 5px;">Need a fresh start? Our robust API allows you to reset your IP address instantly on demand. Whether you're scraping, automating, or just want a clean slate, we've got you covered. No more waiting – control your proxy experience like never before.</p>
                        </div>
                     </div>
                     <img src="../assets/wrenchcolored.png " alt=""  style="width: 200px;height: 200px;margin-right: 150px;">
                     </div>
                    </div>
                    <!--[--><!--]--><!--[--><!--]-->
                    <div style="height: 600px; display:flex; flex-direction:column;align-items: center;">
                     <h2 style="font-size: 40px; font-weight: 600; margin-top: 60px; margin-bottom: 30px;line-height: 38px;">Choose your 4g proxy plan</h2>
                     <div style="padding: 5px;width: 265px; height: 50px; background: #070707; border-radius: 100px; border: 1px #404040 solid; display: flex; justify-content:center;">
                        <div @click="selectProxy('unshared')"  style="cursor: pointer;background-color:black;border-radius: 100px; padding: 0 10px 2px 10px; display: flex;align-items: center;" :class="{selected : selectedProxy=='unshared' }" > <p style="font-weight: 500;"> dedicated proxy</p></div>
                        <div @click="selectProxy('shared')"    style="cursor: pointer;background-color: black;border-radius: 100px;padding: 0 10px 2px 10px; display: flex;align-items: center;" :class="{selected : selectedProxy=='shared' }"><p style="font-weight: 500;" > shared proxy</p></div>
                     </div>
                     <div style="margin-top: 30px;width: 350px;display: flex; justify-content: space-between;">
                        <div>
                           <div style="display: flex;"><img style="height: 20px;" src="../assets/greentick.svg" alt=""><p>Unlimited GB</p></div>
                           <div style="display: flex;"><img style="height: 20px;" src="../assets/greentick.svg" alt=""><p>Instant delivery</p></div>
                        </div>
                        <div>
                           <div style="display: flex;"><img style="height: 20px;" src="../assets/greentick.svg" alt=""><p>Full API acces</p></div>
                           <div style="display: flex;"><img style="height: 20px;" src="../assets/greentick.svg" alt=""><p>Instant IP resets</p></div>
                        </div>
                     </div>
                     <UnsharedPricingSection v-if="selectedProxy==='unshared'"></UnsharedPricingSection>
                     <SharedPricingSection v-if="selectedProxy==='shared'"></SharedPricingSection>
                    </div>
                    <div style="display: flex;justify-content: space-between;align-items: center; width: 100%;height: 250px;background-color: #151515;">
                     <div style="width: 450px;margin-left:325px;">
                        <h2 style="font-size: 40px; font-weight: 600; margin-bottom: 30px;line-height: 38px;">Are you a business ?</h2>
                        <p style="font-weight: 500;">Learn more about our custom solutions for businesses and take advantage of special offers to suit your needs !</p>
                     </div>
                     <div style="cursor: pointer; margin:50px 360px 0px 0px; width: 120px; height: 50px; background: #D4B700; border-radius: 100px; text-align: center;padding:11px 0px 0px 0px ;">
                        <router-link :to="{path: '/contact'}"><p style="color:black;font-weight: 500;">learn more</p></router-link>
                        
                     </div>
                    </div>
                    <div style="height:600px;display: flex;flex-direction: column;align-items: center;background-color: #333333; margin-bottom: -80px;">
                        <h2 style="font-size: 40px; font-weight: 600; margin:60px 0px 70px 0px;line-height: 38px;">frequently asked question</h2>
                        <div style="width: 90%;display: flex;flex-wrap: wrap; justify-content:start;">
                           <div @click="selectQuestion(1)" class="questions"><p class="question-text">Where are you located ?</p> </div>
                           <div @click="selectQuestion(2)" class="questions"><p class="question-text">Do you have any blocked sites ?</p></div>
                           <div @click="selectQuestion(3)" class="questions"><p class="question-text">Do I get access instantly upon purchase ?</p></div>
                           <div @click="selectQuestion(4)" class="questions"><p class="question-text">What payment methods do you accept ?</p></div>
                           <div @click="selectQuestion(5)" class="questions"><p class="question-text">What is the speed of your proxies ?</p></div>
                           <div @click="selectQuestion(6)" class="questions"><p class="question-text">What is the uptime of your proxies ?</p></div>
                           <div @click="selectQuestion(7)" class="questions"><p class="question-text">How do I use the API ?</p></div>
                           <div @click="selectQuestion(8)" class="questions"><p class="question-text">Do you have telegram ?</p></div>
                        </div>
                    </div>
                    <div style="background-color: #333333;height: 150px;">
                     <Transition mode="out-in">
                        <div v-if="question==1"> <h2 class="QA-question">Where are you Located ?</h2> <p class="QA-answer">Our proxies are located in France.</p> </div>
                     <div v-else-if="question==2"><h2 class="QA-question">Do you have any blocked sites?</h2> <p class="QA-answer">Thanks to our 4G proxies, we are unblockable, ensuring uninterrupted access.</p></div>
                     <div v-else-if="question==3"> <h2 class="QA-question">Do I get access instantly upon purchase?</h2> <p class="QA-answer">Yes, you will receive instant access upon purchase.</p> </div>
                     <div v-else-if="question==4"> <h2 class="QA-question">What payment methods do you accept?</h2> <p  class="QA-answer">We accept payments through Stripe.</p></div>
                     <div v-else-if="question==5"><h2 class="QA-question">What is the speed of your proxies?</h2> <p  class="QA-answer">For our dedicated proxies, the average speed is between 5-10 mb/s. For shared proxies, we never go below 1 mb/s. The most crucial factor is the consistent ping. If you are based in Europe, you can expect around 80ms, while elsewhere it's approximately 150ms.</p></div>
                     <div v-else-if="question==6"><h2 class="QA-question">What is the uptime of your proxies?</h2> <p  class="QA-answer">We maintain a 99% uptime for our proxies. However, we do have monthly maintenance to ensure optimal performance.</p> </div>
                     <div v-else-if="question==7"> <h2 class="QA-question">How do I use the API? </h2> <p  class="QA-answer">there's documentation on the site on how to use it</p></div>
                     <div v-else-if="question==8"> <h2 class="QA-question">Do you have Telegram?</h2> <p  class="QA-answer">Yes, contact us on @aegisproxy</p></div>
                     </Transition>
                    </div>
                    <div style="background-color: #333333;height: 50px;"></div>
   <!--      
<div class="VPFeatures VPHomeFeatures" data-v-ca9b87e3="" data-v-f2e31b27="">
                       <div class="container" data-v-f2e31b27="">
                          <div class="items" data-v-f2e31b27="">
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article @click="lol(0)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">⚡️</div>
                                   <h2 class="title" data-v-3a107b6e="">Lightning Fast </h2>
                                   <p class="details" data-v-3a107b6e="">fastest and most stable 4g proxies available</p>
                                </article>
                             </div>
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article @click="lol(1)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">💸</div>
                                   <h2 class="title" data-v-3a107b6e="">Cheapest In The Market</h2>
                                   <p class="details" data-v-3a107b6e="">Our price-performance ratio is one of the best in the industry</p>
                                </article>
                             </div>
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article @click="lol(2)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">💡</div>
                                   <h2 class="title" data-v-3a107b6e="">Highest Trust Score</h2>
                                   <p class="details" data-v-3a107b6e="">Constant research to maintain high scores</p>
                                </article>
                             </div>
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article @click="lol(3)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">🛠️</div>
                                   <h2 class="title" data-v-3a107b6e="">100% uptime</h2>
                                   <p class="details" data-v-3a107b6e="">Never worry about losing connexion during usage</p>
                                </article>
                             </div>
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article @click="lol(4)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">🧼</div>
                                   <h2 class="title" data-v-3a107b6e="">cleanest ip pools</h2>
                                   <p class="details" data-v-3a107b6e="">Never experience ip bans with the cleanest ip pools possible</p>
                                </article>
                             </div>
                             <div class="grid-6 item" data-v-f2e31b27="">
                                <article  @click="lol(5)" class="VPFeature" data-v-f2e31b27="" data-v-3a107b6e="">
                                   <div class="icon" data-v-3a107b6e="">📝</div>
                                   <h2 class="title" data-v-3a107b6e="">Ergonomic User Board</h2>
                                   <p class="details" data-v-3a107b6e="">Our User Board is simple and easy to use</p>
                                </article>
                             </div>
                             
                          </div>
                       </div>
                    </div>
                  -->
                 </div>
              </div>
              <!--
              <div>
               
                <transition mode="out-in">            
                  <div v-if="memory[0] == true" key="memory" style="margin-left:100px;margin-bottom: 50px;">
                  <p  class="text"  data-v-b902bbd7>The fastest in the game ?</p>
                  <br>
                  <p style=" display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px;">
                    4g proxy providers notoriously provide underwhelming speeds for their users. <br>
                    This is due to the <Modal>
						 <template #opener>
							<p  class="opener-text">
								nature of the proxy themselves. <br>
						</p>
						 </template>
						 <template #main>
							<proxy-info></proxy-info>
						 </template>
					</Modal>
                     You can see the issue here: if a cellphone user uses up too much of the available <br> 
                     bandwidth (which happens a lot, since he's a user like you and me...) the internet <br>
					 speed can get very, very slow. <br>
					 On top of that, proxy providers permit multiple client connexions to a single cellphone <br>
					 so you can end up with multiple users on one cellphone, with the cellphone user 
					 being active also ! <br>
                     <br>
                     What distinguishes us from other proxy companies is that: <br>
                    - each proxy is dedicated to one user only <br>
                    - we don't require regular users to share their cellphone connexions, we have <br>
					&nbsp; dedicated devices that share them for us ! <br>
                    Not only this makes us the fastest, but also the most stable (no bandwidth usage spikes, you're <br>
                    the only user)
                  </p>
                </div>
                  <div v-else-if="memory[1] == true" style="margin-bottom: 50px;">
                     <PricingSection></PricingSection>
                </div>
                <div v-else-if="memory[2] == true" style="margin-left:100px; margin-bottom: 50px;">
                  <p  class="text"  data-v-b902bbd7>The stealthiest in the game ?</p>
                  <br>
                  <p style=" display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px;">
                  Websites and scrapers are engaged in a constant battle of detection/anti-detection,  <br>
                  and 4g proxies seem one of the best strategies scrapers have came up with to break through antibot detection. <br> <br>
                  But every month innovative ways to fingerprint proxy connexions are made, and sooner or later 4g proxy providers  <br>
                  will have to adapt or die. Unfortunately for the biggest 4g proxy providers such adaptations are impossible (for now). <br>
                  Aegis on the other hand has the capability to adapt quickly to new fingerprinting methods due to direct access to their devices. <br>
                   </p>
                </div>
				<div v-else-if="memory[3] == true" style="margin-left:100px;margin-bottom: 50px;">
                  <p  class="text" data-v-b902bbd7 style=" margin-bottom: 30px;">100% Uptime</p>
				  <p style=" font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px; margin: auto;">
					Using our proxies, never experience the pain of losing connexion during usage. <br><Modal>
						 <template #opener>
							<p  class="opener-text">
								Traditional 4g proxies
						</p>
						 </template>
						 <template #main>
							<proxy-info></proxy-info>
						 </template>
					</Modal>
					can be unstable due to the cellphone user dropping their connexion. <br>
					Since we have dedicated devices that share the connexion for us, that are active 24/7, <br>
					connexion loss never occurs, guaranteeing a stress free experience.
				  </p>
                </div>
				<div v-else-if="memory[4] == true" style="margin-left:100px;margin-bottom: 50px;">
                  <p  class="text" data-v-b902bbd7 style=" margin-bottom: 30px;">Cleanest Ip Pools</p>
				  <p style="display:inline; font-size: 16px;font-weight: 500; text-align: left;  white-space: pre-wrap; line-height: 20px; margin: auto">
					Using 4G proxies makes it virtually impossible to get banned. <br>
					 4G ip's are used by hundreds of  regular	 users at the same time, <br>
					 making it impossible for companies to blacklist the ip's since it <br>
					 would imply banning hundreds, if not thousands of normal users. <br>
					 Even in the event of encountering a blacklisted ip, you can just <br>
					 wait for the next ip rotation </p><p class="opener-text" @click="lol(5)"> using our user board. </p>  
				  
                </div>
				<div v-else-if="memory[5] == true" style="margin-left: 100px;margin-bottom: 50px;">
                  <p  class="text" data-v-b902bbd7 style=" margin-bottom: 30px;">Ergonomic User Board</p>
				  <Modal>
					<template #opener>
						<img src="../assets/userboard.png" alt="" style="width:50%;height:50%;margin-bottom: 20px;">
					</template>
					<template #main>
						<LoginForm></LoginForm>
					</template>
				  </Modal>
                </div>
				
              </transition>  
              
              </div>
              -->
              <div style="height: 100px; display: flex;justify-content: space-around;margin-bottom:70px;margin-top:-80px">
                  <div style="width:300px">
                     <div style="border-bottom: solid 1px;border-color:white;padding-bottom:8px"><h3 style="font-weight:500;">LEGAL PAGES</h3></div>
                     <p style="font-weight:300">Terms of use</p>
                     <p style="font-weight:300">Privacy Policy</p>
                  </div>
                  <div style="width:300px">
                     <div style="border-bottom: solid 1px;border-color:white;padding-bottom:8px"><h3 style="font-weight:500; ">CONTACTS</h3></div>
                     <p style="font-weight:300">Contact us</p>
                     <p style="font-weight:300">Email</p>
                     <p style="font-weight:300">LinkedIn</p>
                     <p style="font-weight:300">Telegram</p>
                  </div>
                  <div style="width:300px">
                     <div style="border-bottom: solid 1px;border-color:white;padding-bottom:8px"><h3 style="font-weight:500;">USEFUL LINKS</h3></div>
                     <p style="font-weight:300">FAQ</p>
                     <p style="font-weight:300">Blog</p>
                     <p style="font-weight:300">What's my IP ?</p>
                  </div>
              </div>
              <footer class="VPFooter" data-v-37134a4b="" data-v-27be7a67="">
                
                 <div class="container" data-v-27be7a67="">
                    <p class="copyright" data-v-27be7a67="">Copyright © 2022-present Aegis</p>
                 </div>
              </footer>
              <!--[--><!--]-->
           </div>
</template>

<script>
import { ref } from 'vue';
import Modal from '../components/Modal.vue';
import LoginForm from '../components/LoginForm.vue';
import Purchase from '@/components/Purchase.vue'
import { db, functions, httpsCallable } from "@/firebase/config"
import {doc, updateDoc, addDoc, collection} from 'firebase/firestore'
import ProxyInfo from '../components/ProxyInfo.vue';
import NavBar from '@/components/NavBar.vue';
import Logo from '@/components/Logo.vue';
import PricingSection from '@/components/PricingSection.vue';
import getUser from '@/composables/getUser'
import UnsharedPricingSection from '@/components/UnsharedPricingSection.vue';
import SharedPricingSection from '@/components/SharedPricingSection.vue'
export default {
    components: { Modal, LoginForm, Purchase, ProxyInfo, NavBar, Logo, PricingSection, UnsharedPricingSection, SharedPricingSection  },
    setup() {

      const {user} = getUser()
      const memory = ref([false,false,false])
      const test = true
      let index = 99
      const lol = (ind) => {
         if(index == ind) {
         console.log(ind)
         console.log("made it here")
         memory.value[ind] = false
         console.log(memory.value[ind])
         index = 99
         }
         else { 
            memory.value[index] = false
            index = ind
            memory.value[ind] = true 
            console.log(memory.value)
         }
      }
      const createStripeCheckout = httpsCallable(functions, 'createStripeCheckout')
      const stripe = Stripe("pk_test_51LuGRwIrspSkXtrvBwTwyzrDxJ4bLiQv3IBcRMCba9eKtk756g9KSOEojSxk9FayshHUpj2xby27ywij8dnO6VsR00yl6gNYkn")
      const yes = async () => {
      const response = await createStripeCheckout()
      const sessionId = response.data.id
      const userRef = collection(db, 'users')
      await addDoc(userRef, {sessionId})
      stripe.redirectToCheckout({
         sessionId: sessionId
      })
      }
      const selectedProxy = ref('unshared')
      const selectProxy = (type) => {
         selectedProxy.value = type
         console.log(selectedProxy.value)
      }

      const question = ref(1)
      const selectQuestion = (choice) => {
         question.value = choice
         console.log(question.value)
      }
        return {lol, memory, test, yes, user, selectProxy, selectedProxy, question, selectQuestion}
      }
}
</script>

<style>
.questions {
   cursor: pointer;
   height: 25vh;
   width: 20vw;
   background-color: #282828;
   border-radius: 10px;
   margin: 5px 10px 5px 10px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.question-text {
   width: 14vw;
   text-align: center;
   font-weight: 500;
}

.selected {
   background-color: #404040 !important; 
}

.QA-question {
   font-size: 35px;
    font-weight: 600;
    margin: 0px 0px 30px 70px;
    line-height: 38px;
}

.QA-answer {
   width: 900px;
   margin: 0px 0px 30px 70px;
   font-weight: 500;
}
</style>