<template>
    <h1 style="font-size: 30px; font-weight: 600; margin:0px 0px 30px 20px;line-height: 38px;">Billing History</h1>
          <div style="display: flex; width: 1000px;">
          <h1 style="font-weight:500;font-size: 20px; width: 175px; margin-bottom: 15px;">Billed Amount</h1>
          <h1 style="font-weight:500;font-size: 20px;width: 200px">Subscription Plan</h1>
          <h1 style="font-weight:500;font-size: 20px;width:400px" >Date</h1>
          </div>
          <div style="display: flex; width: 1000px;">
          <h1 style="font-weight:300; width: 175px; font-size: 15px; margin: 3px 0px 3px 0px;">€4.99</h1>
          <h1 style="font-weight:300;width: 200px;font-size: 15px;  margin: 3px 0px 3px 0px;">Shared Proxy, 1 day</h1>
          <h1 style="font-weight:300;width:400px;font-size: 15px;  margin: 3px 0px 3px 0px;" >October 16th, 2023 at 9:34pm [TIMEZONE]</h1>
          </div>
          
          
</template>

<script>
import { ref } from 'vue';

export default {

    name: 'BillingHistory',
    setup() {

        return {
        };
}
};
</script>

<style scoped>

</style>
      