import getDocument from "./getDocument"
import getUser from "./getUser"
import { ref } from "vue"

const error = ref(null)
const userObject = ref(localStorage.getItem('user'))
const user = ref(JSON.parse(userObject.value))

const getUserInfo = () => {
    try {
        if(user.value) {
            return getDocument('users', user.value.uid)
        }
        else {
            return {}
        }
        
    } catch (err) {
        error.value = err.message
        
    }
}

export default getUserInfo