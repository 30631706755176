<template>
<div  v-if="userInfo" style="padding-top:150px;display: flex;justify-content: center; align-items: left;flex-direction: column; width: fit-content; margin: auto;">
      <p style="font-size:xx-large;margin-bottom: 20px; font-weight: 600;">Control Panel</p>
      <div class="container">
      <div style="display:flex;flex-direction:column;justify-content:start">
      <div style="width: 250px">
          <p  style="font-size: 25px;font-weight: 600;margin-bottom:20px">Proxy Credentials</p>
          <div style="margin-bottom:10px">
            <div style="display:flex; justify-content:start; align-items:center"><p style="margin-bottom:5px">http logins</p> <svg  @click="copyCred('httpProxy')" style="margin-left: 10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
              </div>
            <div class="http-login">
              <div v-for="login in userInfo.logins" :key="login">
              <div>
                <p style="font-weight:500">{{login.httpProxy}}</p>
              </div>
            </div>
            </div>
          </div>
          <div style="margin-bottom:10px">
            <div style="display:flex; justify-content:start; align-items:center"><p style="margin-bottom:5px">api ports</p> <svg  @click="copyCred('api')" style="margin-left: 10px" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
              </div>
            <div class="http-login">
              <div v-for="login in userInfo.logins" :key="login">
              <div>
                <p style="font-weight:500">{{login.api}}</p>
              </div>
            </div>
            </div>
          </div>
      </div>
      <div style="margin:80px 0px 0px 0px;width: 250px;">
          <p style="font-size: 25px;font-weight: 600;margin-bottom:20px">Ip Whitelist</p>
          <div v-for="ip in userInfo.ipWhiteList" :key="ip" style="display:flex; justify-content:space-between;align-items:center;margin-bottom: 2px;font-weight: 500"><p>{{ip}}</p><svg @click="removeIp(ip)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="8" y1="12" x2="16" y2="12"></line></svg></div>
          <div style="display:flex;justify-content: start; margin-top:5px;"><input v-if="ipInput" @keydown.enter="addIp" v-model="newIp" type="text" placeholder="hit Enter key to add IP"><svg @click="ipInput = !ipInput" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg></div>
      </div>
      </div>
      <div>
        <div style="display:flex;flex-direction:column;align-items:center;justify-content: start;">
          <p style="font-size: 25px;font-weight: 600;text-align:center; margin-bottom: 20px;">Data Plan</p>
          <div role="progressbar" :aria-valuenow="Math.round(GBconsumed/(userInfo.GBPlan*1024) * 100) " aria-valuemin="0" aria-valuemax="100" :style="{'--value': Math.round(GBconsumed/1024/(userInfo.GBPlan) * 100)}"></div>
          <p style="text-align:center; font-weight:600;margin: 15px 0px 15px 0px;">{{Math.round(GBconsumed/1024)}}/{{userInfo.GBPlan}} GB used</p>
          <Modal>
                      <template #opener>
              <button>Purchase GB's</button>	
                      </template>
                      <template #main>
                          <Purchase></Purchase>
                      </template>
                  </Modal>
                  
      </div>
      <div style="margin-top: 78px;">
        <p style="font-size: 25px;font-weight: 600;text-align:center; margin-bottom: 20px;">Set Rotation time</p>
        <div style="display:flex;margin-bottom: 20px;justify-content: center;align-items: center;">
          <p style="margin-right: 10px;">port</p>
          <!--<select name="" id="" v-model="port">
            <option value="all"> all</option>
            <option v-for="login in userInfo.logins" :value="port.api">{{ login.api.match(/:(\d+)/)[1] }}</option>
          </select> !-->
          <p @click="showPorts=!showPorts" style="border:solid 1px orange; border-radius: 4px; padding: 0px 5px 5px 5px; margin-top: 5px;cursor: pointer;">
            select
          </p>
          <p style="margin-left: 5px;">:</p>
          <input class="input-rotation" type="number" v-model="timeRotation">
          <p>seconds</p>
        </div>
        <div class="port-container" v-if="showPorts">
          <div @click="allPorts()" class="port-cell">all</div>
          <div v-for="(login,index) in userInfo.logins" :key="index" class="port-cell" @click="selectPort(login.api)" :class="{portCellActive: portObject[login.api]}">
            {{ login.api.match(/:(\d+)/)[1] }}
          </div>
        </div>
        <button id="button-rotation" @click="startRotation()" v-if="!pending">start rotation</button>
        <button id="button-rotation" disabled v-if="pending"> loading</button>
        <div v-if="successRotation" style="color:green">
        {{ successRotation }}</div>
        <div v-if="errorRotation" style="color:red">
        {{ errorRotation }}</div>
      </div>
      </div>
    </div>
    </div>
    <div v-else>
      <panel-skeleton></panel-skeleton>   
    </div>
  </template>
  
  <script>

  import getUserInfo from '@/composables/getUserInfo'
  import { db, functions, httpsCallable } from '@/firebase/config';
  import { addDoc, arrayRemove, arrayUnion, collection, doc, getDoc, updateDoc } from '@firebase/firestore';
  import { ref } from 'vue';
  import Modal from '@/components/Modal.vue';
  import Purchase from '@/components/Purchase.vue';
  import PanelSkeleton from './PanelSkeleton.vue';
  import getCollection from '@/composables/getCollection';
  import axios from 'axios'
  export default {
    components:{Modal, Purchase, PanelSkeleton},
    setup() {
      const userInfo = getUserInfo()
      const ipInput = ref(false)
      const GBconsumed = ref(null)
      const userObject = ref(localStorage.getItem('user'))
      const user = ref(JSON.parse(userObject.value))
      const getDataCons = httpsCallable(functions, "getDataConsumption")
      getDataCons({uid: user.value.uid}).then(res =>GBconsumed.value = res.data.total)
      const refreshData = async () => {
        console.log("huh")
        await getDataCons({uid: user.value.uid}).then(res =>GBconsumed.value = res.data.total)
        console.log("haha")
        console.log(GBconsumed.value)
      }
      //axios.get("http://51.178.43.147:3000/getData/" + user.value.uid).then(res => GBconsumed.value = res)
      const newIp = ref('')
      const copyCred = async (param) => {
          const yo = []
          userInfo.value.logins.forEach(proxy => { 
            yo.push(proxy[param])
          });
          let text = yo.join("\n");
          await navigator.clipboard.writeText(text);
          }
          const removeIp = async (ip) => {
          const userRef = doc(db, 'users', user.value.uid)
          await updateDoc(userRef, {
            ipWhiteList: arrayRemove(ip)
          })
          }
        const addIp = async () => {
          const regexTest = /(?<!.)[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(?!.)/gm
          if (regexTest.test(newIp.value)) {
          const userRef = doc(db, 'users', user.value.uid)
          await updateDoc(userRef, {
              ipWhiteList: arrayUnion(newIp.value)
          })
          ipInput.value = false
          newIp.value = ""
          }
      }

      const test = httpsCallable(functions, 'expireTest')
      const lol = ref(null)
      const testing = async () => {
        lol.value = await test()
      }
      const port = ref("all")
      const showPorts = ref(false)
      const portObject = ref({})
      let switchAll = false
      const allPorts = () => {
        switchAll = !switchAll
        for (let i =0; i<userInfo.value.logins.length;i++) {
          console.log(userInfo.value.logins[i].api)
          console.log(portObject.value)

          portObject.value[userInfo.value.logins[i].api] = switchAll
        }
      }
      const selectPort = (key) => {
        console.log(key)
        console.log("hahaha")
        console.log("portObject is", portObject.value)
        console.log(key in portObject.value)
        if (key in portObject.value == false) {
          console.log("made it here")
          portObject.value[key] = true
          //portObject.value[key] == true
        }
        else if (portObject.value[key] == false) {
          portObject.value[key] = true
        }
        else {
          portObject.value[key] = false
        }
      }
      const timeRotation = ref(60)
      const successRotation = ref(null)
      const errorRotation = ref(null)
      const pending = ref(false)
      const sendRotation = httpsCallable(functions, "startRotation")
      const startRotation = async () => {
        pending.value = true
        successRotation.value = null
        errorRotation.value = null
        let check = true
        if (timeRotation.value < 60) {
          errorRotation.value = "time specified too low, must be above 60 seconds"
          check = false
        }
        if (!userInfo.value.activePlan) {
          errorRotation.value = "you have no active plans, please purchase one"
          check = false
        }

        if (check) {
          const portArray = Object.keys(portObject.value).filter(key => portObject.value[key] == true)
          console.log(portArray)
          const rotationRequest = {time:timeRotation.value, ports:portArray, ip: userInfo.value.serverIP}
          console.log("user rotation request", rotationRequest)
          const response = await sendRotation(rotationRequest)
          console.log(response.data)
          if (response.data.status == 200){
            successRotation.value = response.data.response
            console.log(successRotation.value)
            errorRotation.value = null
          }
          else {
            errorRotation.value = "rotation failed, try again in a few seconds"
            successRotation.value = null
          }
        }
        pending.value = false
        Object.keys(portObject.value).forEach(key => {portObject.value[key] = false;});
      }
      return {userInfo, copyCred, newIp, addIp, ipInput, removeIp, user, testing, lol, GBconsumed, port, showPorts, portObject, selectPort, timeRotation, successRotation, errorRotation, startRotation, pending, allPorts}
    }
  }
  </script>
  <style scoped>
  svg:hover {
  cursor:pointer;
  }
  button{
      border: 2px solid orange;
      border-radius: 20px;
      padding-left: 15px;
      padding-right:15px;
      padding-bottom: 4px;
      font-weight: 600;
      color:white;
  }
  button[disabled] {
    border: 2px solid orange;
    border-radius: 20px;
    padding-left: 15px;
    padding-right:15px;
    padding-bottom: 4px;
    font-weight: 600;
    color:white;
    cursor:not-allowed;
  }
  select {
    border-radius: 5px;
    text-align: center;
    font-size: 15px;
    padding: 5px;
    border: solid 0px;
    outline: none;
    cursor: pointer;
  }
  .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 10px; 
      padding: 50px 80px 50px 80px;
      width: 60vw;
      background-color: #2f2f2f;
      margin-bottom: 50px;
  }
  input:focus{
      outline: none;
  }

  .input{
    margin-left: 5px;
    margin-right: 5px;
    border: solid 1px black;
    border-radius: 5px;
    padding: 1px 5px 3px 5px ;
    cursor: pointer;
  }
  .input:focus{
    outline: none;
  }
  .input-wrap {
  position: relative;
}
.input-wrap .input {
  position: absolute;
  width: 100%;
  left: 0;
}
.input-rotation {
  margin-left: 10px;
  margin-right: 5px;
  width: 40px;
  text-align: center;
  padding: 7px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input:focus{
    outline: none;
}

.port-container {
  width: 232px;
  height: 150px;
  padding-top: 2px;
  margin-bottom:10px;
  border-radius:10px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow: auto;
}

.port-cell {
  cursor: pointer;
  min-width: 50px;
  text-align: center;
  margin: 4px;
  padding: 2px 3px 3px 3px;
  height: fit-content;
  border: solid 2px rgb(28, 27, 27);
  border-radius: 5px ;
}
.portCellActive {
  border:  solid 2px orange;
}

#button-rotation {
  border: 1px solid orange;
}

  .http-login{
    width: 300px; /* width of the div */
  max-height: 100px; /* max height of the div */
  overflow-y: scroll; /* adds scrollbars to the div on the y-axis (height) */
  overflow-x: hidden; /* hide scrollbars on the x-axis (width) */
  }
  .circle {
      border:20px solid rgb(94, 94, 101);
      width:200px;
      height:200px;
      border-radius: 50%;
  }
  @keyframes growProgressBar {
    0%, 33% { --pgPercentage: 0; }
    100% { --pgPercentage: var(--value); }
  }
  
  @property --pgPercentage {
    syntax: '<number>';
    inherits: false;
    initial-value: 0;
  }
  /* width and height of the scrollbar */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* style of the track */
::-webkit-scrollbar-track {
  background-color: #3c3a3a;
  border-radius: 10px;
}
/* style of the thumb */
::-webkit-scrollbar-thumb {
  background-color: rgb(88, 88, 88);
  border-radius: 10px;
}

  
  div[role="progressbar"] {
    --size: 12rem;
    --fg: rgb(255, 196, 0);
    --bg: rgb(163, 163, 186);
    --pgPercentage: var(--value);
    animation: growProgressBar 3s 1 forwards;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: 
      radial-gradient(closest-side, #242424 80%, transparent 0 99.9%, #242424 0),
      conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
      ;
    font-family: Helvetica, Arial, sans-serif;
    font-size: calc(var(--size) / 5);
    color: var(--fg);
  }
  
  div[role="progressbar"]::before {
    counter-reset: percentage var(--value);
    content: counter(percentage) '%';
  }
  
  /* demo */
  </style>