<template>
  <div style="background-color:#2f2f2f;
  border-radius: 16px;">
    <transition mode="out-in">
    <div v-if="!toggleShow">
    <form @submit.prevent="handleSubmit">
    <p style="text-align: center; font-size: 30px; font-weight: 600;">sign up</p>
        <input type="email" placeholder="Email" v-model="email">
        <input type="password" placeholder="Password" v-model="password">
        <div v-if="errorSignup" class="error">{{ errorSignup }}</div>
        <div style="display: flex; justify-content: center;">
          <button v-if="!isPendingSignup">Sign up</button>
          <button v-else disabled>Loading</button>
        </div>
        <br>
        <p style="display:inline-block">Already have an account ?</p> <div style="display:inline-block;width:60px"><a class="VPLink link VPNavBarMenuLink" style="cursor:pointer;" @click="toggleShow=!toggleShow" > Login</a></div>
    </form>
  </div>
  <div v-else>
    <form @submit.prevent="handleSubmit">
    <p style="text-align: center; font-size: 30px; font-weight: 600;">log in</p>
        <input type="email" placeholder="Email" v-model="email">
        <input type="password" placeholder="Password" v-model="password">
        <div v-if="errorSignup" class="error">{{ errorSignup }}</div>
        <div style="display: flex; justify-content: center;"><button v-if="!isPendingSignup">Log in</button></div>
        <button v-if="isPendingSignup" disabled>Loading</button><br>
        <p  style="display:inline-block">Already have an account ?</p> <div style="display:inline-block;width:60px"><a class="VPLink link VPNavBarMenuLink" style="cursor:pointer;" @click="toggleShow=!toggleShow" > Sign up</a></div>
    </form>
  </div>
  </transition>
  </div>
</template>

<script>
import { ref } from 'vue';
import useSignup from '@/composables/useSignup'
import useLogin from '@/composables/useLogin'
export default {

  setup() {
    const email = ref('')
    const password = ref('')
    const toggleShow = ref(false)

    const { error:errorSignup, signup, isPending:isPendingSignup } = useSignup()
    const { error:errorLogin, login,isPending:isPendingLogin } = useLogin()

    const handleSubmit = async () => {
      if (!toggleShow.value) {
        errorSignup.value = null
        await signup(email.value, password.value)
      }
      else {
        errorLogin.value = null
        await login(email.value, password.value)
      }
    }
    return {email,password,isPendingSignup,isPendingLogin,errorSignup,errorLogin, handleSubmit, toggleShow}
    }
}
</script>

<style scoped>

form {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
}
input {
  background-color: #2f2f2f ;
  border:0px;
  border:1px solid rgb(218, 133, 14);
  border-radius:5px;
  padding: 10px;
  outline: none;
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin: 40px auto;
  color: white;
  font-size: 15px;
}

button{
    border: 2px solid orange;
    border-radius: 20px;
    padding-left: 15px;
    padding-right:15px;
    padding-bottom: 4px;
    font-weight: 600;
    color:white;
}
p{
    color:white;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
a {
    color:white;
    font-weight: 600;
}
a:hover {
    color:orange
}
</style>