<template>
<div class="aegis-logo">
  <svg class="shield" width="350" height="450" viewBox="0 0 50 60">
    <defs>
      <linearGradient id="gradient1" x1="0%" y1="100%" x2="0%" y2="0%">
        <stop offset="0%" style="stop-color:hsl(55deg 100% 50%)" />
        <stop offset="10%" style="stop-color:hsl(48deg 100% 50%)" />
        <stop offset="22%" style="stop-color:hsl(40deg 100% 50%)" />
        <stop offset="36%" style="stop-color:hsl(30deg 100% 55%)" />
        <stop offset="49%" style="stop-color:hsl(17deg 100% 59%)" />
        <stop offset="60%" style="stop-color:hsl(3deg 95% 61%)" />
        <stop offset="70%" style="stop-color:hsl(346deg 83% 51%)" />
        <stop offset="79%" style="stop-color:hsl(336deg 100% 41%)" />
        <stop offset="85%" style="stop-color:hsl(329deg 100% 36%)" />
        <stop offset="91%" style="stop-color:hsl(319deg 100% 30%)" />
        <stop offset="95%" style="stop-color:hsl(304deg 100% 23%)" />
        <stop offset="98%" style="stop-color:hsl(281deg 100% 21%)" />
        <stop offset="100%" style="stop-color:hsl(240deg 100% 20%)" />
      </linearGradient>
    </defs>
    <polygon points="25,0 50,30 25,60 0,30" fill="url(#gradient1)" />
    <polygon points="25,15 40,30 25,45 10,30" fill="#242424" />
  </svg>
</div>

</template>
    
<script>
export default {   
    }
</script>
    
<style>
    .aegis-logo {
  display: flex;
  align-items: center;
  filter: drop-shadow(2px 4px 6px rgba(255, 146, 4, 0.4));
  animation: float 3s ease-in-out infinite;
}

.shield {
  margin-right: 10px;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

</style>