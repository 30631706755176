<template>
    <div style="display: flex; justify-content: space-around; width: 900px; margin-top:20px;">
                        <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center; width: 200px; height:225px; background: #1B1B1B; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset; border-radius: 10px; border: 1px #484747 solid">
                           <div style="margin-top: 20px;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">1</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">Day</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">4.99$</h2>
                              <p>per day</p>
                           </div>
                           <Modal v-if="user">
                              <template #opener>
                                 <div style="cursor:pointer;margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                             <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>	
                              </template>
                              <template #main>
                                 <Purchase  duration="Day" :price="4.99" ></Purchase>
                              </template>
                           </Modal>
                           <Modal v-else>
                                 <template #opener>
                                    <div style="cursor:pointer; margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                                <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>
                                 </template>
                                 <template #main>
                                    <LoginForm></LoginForm>
                                 </template>

                           </Modal>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center; width: 200px; height:225px; background: #1B1B1B; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset; border-radius: 10px; border: 1px #484747 solid">
                           <div style="margin-top: 20px;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">1</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">Week</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">24.99$</h2>
                              <p>per week</p>
                           </div>
                           <Modal v-if="user">
                              <template #opener>
                                 <div style="cursor:pointer;margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                             <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>	
                              </template>
                              <template #main>
                                 <Purchase  duration="week" :price="24.99" ></Purchase>
                              </template>
                           </Modal>
                           <Modal v-else>
                                 <template #opener>
                                    <div style="cursor:pointer; margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                                <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>
                                 </template>
                                 <template #main>
                                    <LoginForm></LoginForm>
                                 </template>

                           </Modal>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center; width: 200px; height:225px; background: #1B1B1B; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset; border-radius: 10px; border: 1px #484747 solid">
                           <div style="margin-top: 20px;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">1</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">Month</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">69.99$</h2>
                              <p>per month</p>
                           </div>
                           <Modal v-if="user">
                              <template #opener>
                                 <div style="cursor:pointer;margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                             <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>	
                              </template>
                              <template #main>
                                 <Purchase  duration="Month" :price="69.99" ></Purchase>
                              </template>
                           </Modal>
                           <Modal v-else>
                                 <template #opener>
                                    <div style="cursor:pointer; margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                                <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>
                                 </template>
                                 <template #main>
                                    <LoginForm></LoginForm>
                                 </template>

                           </Modal>
                        </div>
                        <div style="display: flex;flex-direction: column;justify-content: space-between;align-items: center; width: 200px; height:225px; background: #1B1B1B; box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) inset; border-radius: 10px; border: 1px #484747 solid">
                           <div style="margin-top: 20px;display: flex;flex-direction: column;justify-content: space-around;align-items: center;">
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">12</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">Months</h2>
                              <h2 style="font-size: 30px; margin:5px;font-weight: 600;">49.99$</h2>
                              <p>per month</p>
                           </div>
                           <Modal v-if="user">
                              <template #opener>
                                 <div style="cursor:pointer;margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                             <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>	
                              </template>
                              <template #main>
                                 <Purchase  duration="Year" :price="599.88" ></Purchase>
                              </template>
                           </Modal>
                           <Modal v-else>
                                 <template #opener>
                                    <div style="cursor:pointer; margin-bottom: 30px;display: flex;justify-content: center;width: 100px; height: 30px; background: #D4B700; border-radius: 100px; border: 1px black solid">
                                                <p style="margin-top:2px;font-size: 10px; color: black; font-weight: 700;">choose this plan</p></div>
                                 </template>
                                 <template #main>
                                    <LoginForm></LoginForm>
                                 </template>

                           </Modal>
                        </div>
                     </div>
</template>
  
<script>
import { ref } from 'vue';
import getUser from '@/composables/getUser'
import Modal from '../components/Modal.vue';
import LoginForm from '../components/LoginForm.vue';
import Purchase from '@/components/Purchase.vue'
export default {
    components: {Modal, LoginForm, Purchase},
    name: 'UnsharedPricingSection',
    setup() {
        const {user} = getUser()
    
        return {
            user
      };
    }
  };
  </script>
  
  <style scoped>
  </style>
  