import { ref } from 'vue'
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth';

// refs
const user = ref(auth.currentUser)
console.log("lol")
// auth changes
onAuthStateChanged(auth, _user => {
  console.log('User state change. Current user is:', _user)
  user.value = _user
  localStorage.setItem('user', JSON.stringify(_user))
});

const getUser = () => {
  return { user } 
}

export default getUser