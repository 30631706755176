<template>
  <html lang="en-US" class="dark">
     <body>
        <div id="app">
			<NavBar></NavBar>
			<router-view></router-view>
        </div>
     </body>
  </html>
  </template>
  <script> 
import NavBar from './components/NavBar.vue';'../src/components/NavBar.vue'
import '../src/assets/main.css'
    export default {
		components:{NavBar},
		setup() {
		}
    }
  </script>