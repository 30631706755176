<template>
    <h1 style="font-size: 30px; font-weight: 600; margin:0px 0px 30px 20px;line-height: 38px;">My Proxies</h1>
          <div style="display: flex; width: 1000px;">
          <h1 style="font-weight:500;font-size: 20px; width: 125px; margin-bottom: 15px;">Proxy</h1>
          <h1 style="font-weight:500;font-size: 20px;width: 100px">Type</h1>
          <h1 style="font-weight:500;font-size: 20px;width:175px" >Username</h1>
          <h1 style="font-weight:500;font-size: 20px; width: 150px;">Password</h1>
          <h1 style="font-weight:500;font-size: 20px;">Subscription Status</h1>
          </div>
          <div v-if="proxyPasses" v-for="(plan, index) in proxyPasses" :key="index" style="display: flex; width: 1000px;">
            <h1 style="font-weight:300; width: 125px; font-size: 15px; margin: 3px 0px 3px 0px;">my Proxy {{ index + 1 }}</h1>
            <h1 style="font-weight:300;width: 100px;font-size: 15px;  margin: 3px 0px 3px 0px;">{{plan.shared ? "shared" : "dedicated"}}</h1>
            <h1 style="font-weight:300;width:175px;font-size: 15px;  margin: 3px 0px 3px 0px;" >{{plan.username}}</h1>
            <div style="font-weight:300;width:150px;font-size: 15px;  margin: 3px 0px 3px 0px;display: flex;">{{ plan.password }}</div>
            <h1 style="font-weight: 300; font-size: 15px; margin: 3px 0px 3px 0px;"> Active until  {{ plan.subscriptionStatus }}</h1>
          </div>
          <div v-else >
          buy proxies</div>
          
          
</template>

<script>
import { onMounted, ref } from 'vue';
import { db } from "@/firebase/config";
import { collection, getDocs, where } from "firebase/firestore";
import { query } from "firebase/firestore";
export default {

    name: 'MyProxies',
    setup() {
        const userObject = ref(localStorage.getItem('user'))
        const user = ref(JSON.parse(userObject.value))
        console.log("user, ", user.value.uid)
        const planQuery = query(collection(db, 'plans'), where("userUid", "==", user.value.uid))
        const plans = ref([])
        const proxyPasses = ref([])
        onMounted( async () => {
            const planSnapshot = await getDocs(planQuery)
            planSnapshot.forEach(plan => plans.value.push({...plan.data(), id:plan.id}))
            console.log(plans.value.length)
            plans.value.forEach(plan => {
                for (let key in plan.passes) {
                    const date = plan.expiryDate.toDate();
                    const hours = date.getHours();
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const ampm = hours >= 12 ? 'pm' : 'am';
                    const formattedHours = hours % 12 || 12; // Convert 24-hour format to 12-hour format
                    const formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()} ${formattedHours}:${minutes}${ampm}`;
                    console.log(formattedDate);
                    proxyPasses.value.push({
                        shared: plan.shared,
                        username: key,
                        password: plan.passes[key],
                        subscriptionStatus: formattedDate
                    })
                }
            })
        })
        return {
            plans,
            proxyPasses
        };
}
};
</script>

<style scoped>

</style>
      