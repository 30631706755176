<template>
  <h1 style="font-size:90px">no</h1>
</template>

<script>
export default {

}
</script>

<style>

</style>