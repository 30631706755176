<template>
    <h1 style="font-size: 30px; font-weight: 600; margin:0px 0px 30px 20px;line-height: 38px;">Subscriptions</h1>
          <div style="display: flex; width: 1000px;">
          <h1 style="font-weight:500;font-size: 20px; width: 150px; margin-bottom: 15px;">Order No.</h1>
          <h1 style="font-weight:500;font-size: 20px;width: 150px">Type</h1>
          <h1 style="font-weight:500;font-size: 20px;width:400px" >Subscription Status</h1>
          <h1 style="font-weight:500;font-size: 20px;">Payment Method</h1>
          </div>
          <div style="display: flex; width: 1000px;">
          <h1 style="font-weight:300; width: 150px; font-size: 15px; margin: 3px 0px 3px 0px;">091838813131</h1>
          <h1 style="font-weight:300;width: 150px;font-size: 15px;  margin: 3px 0px 3px 0px;">Shared Proxy, 1 day</h1>
          <h1 style="font-weight:300;width:400px;font-size: 15px;  margin: 3px 0px 3px 0px;" >Active until October 17th, 2023 at 9:34pm [TIMEZONE]</h1>
          <div style="font-weight:300;font-size: 20px;font-size: 15px;  margin: 3px 0px 3px 0px;display: flex;"><p style="width: 100px;">VISA </p> <p style="text-decoration: underline;">Extend Subscription</p></div>
          </div>
          <div v-if="plans" v-for="(plan, index) in plans" :key="index" style="display: flex; width: 1000px;">
            <h1 style="font-weight:300; width: 150px; font-size: 15px; margin: 3px 0px 3px 0px;">{{ plan.subscriptionId }}</h1>
          <h1 style="font-weight:300;width: 150px;font-size: 15px;  margin: 3px 0px 3px 0px;">{{ plan.quantity }} {{ plan.shared ? "shared" : "dedicated "}}  proxy  monthly</h1>
          <h1 style="font-weight:300;width:400px;font-size: 15px;  margin: 3px 0px 3px 0px;" >Active until October 17th, 2023 at 9:34pm [TIMEZONE]</h1>
          <div style="font-weight:300;font-size: 20px;font-size: 15px;  margin: 3px 0px 3px 0px;display: flex;"><p style="width: 100px;">VISA </p> <p style="text-decoration: underline;">Extend Subscription</p></div>
          </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { db } from "@/firebase/config";
import { collection, getDocs, where } from "firebase/firestore";
import { query } from "firebase/firestore";
export default {

    name: 'MySubscriptions',
    setup() {
        const userObject = ref(localStorage.getItem('user'))
        const user = ref(JSON.parse(userObject.value))
        console.log("user, ", user.value.uid)
        const planQuery = query(collection(db, 'plans'), where("userUid", "==", user.value.uid), where("type","==","subscription"))
        const plans = ref([])
        const stripe = Stripe("pk_test_51LuGRwIrspSkXtrvBwTwyzrDxJ4bLiQv3IBcRMCba9eKtk756g9KSOEojSxk9FayshHUpj2xby27ywij8dnO6VsR00yl6gNYkn")
        onMounted( async ()=> {
            const planSnapshot = await getDocs(planQuery)
            planSnapshot.forEach(plan => plans.value.push({...plan.data(), id:plan.id}))
            const subscription = await stripe.subscriptions.retrieve(plans.value[0]);
            console.log(subscription)
        })
        return {
            plans
        };
}
};
</script>

<style scoped>

</style>
      