<template>
  <div style="padding:100px 0px 50px 0px;display: flex;justify-content: center; align-items: left;flex-direction: column; width: fit-content; margin: auto;">
    <div class="container" style="display:flex;justify-content: start;align-items: start;">
        <div style=" padding:10px 50px  0px 0px">
            <p @click="lol(0)" class="menu-selection">change ip</p>
            <p @click="lol(1)" class="menu-selection">rotate ip</p>
            <p @click="lol(2)" class="menu-selection">stop rotation</p>
            <p @click="lol(3)" class="menu-selection">check consumption</p>
        </div>
        <div style="width: 700px;border-left:solid orange 3px; padding-left: 90px;">
            <div v-if="memory[0]">
            <p class="api-title">Change IP</p>
            <div style="padding-left:15px">
                <p class="api-subtitle">use case</p>
            <p>By making a get request on this endpoint you will instantly <br> change the ip on the specified <p @mouseenter="portHover = true" @mouseleave="portHover = false" style="color
            :orange; display:inline;cursor: pointer;">port</p> in the url parameters</p>
            <p class="api-subtitle">endpoint</p>
            /changeIp
            <p class="api-subtitle">request type</p>
            GET
            <p class="api-subtitle">parameters</p>
           <p :class="{portHover:portHover}">port : the port number of the ip you wish to change </p> 
            <p class="api-subtitle">url example</p>
            <div >
                <p>server IP : </p>
                <input v-model="serverIp" type="text" name="" id="">
                <p :class="{portHover:portHover}" >port : </p> 
                <input v-model="port" type="number" name="" id="">
            </div>
            <p style="margin-top: 20px;">http://{{serverIp}}:{{port}}/changeIp</p>
            </div>
        </div>
        <div v-if="memory[1]">
            <p class="api-title">Rotate IP</p>
            <div style="padding-left: 15px;">
            <p class="api-subtitle">use case</p>
            <p>By making a get request on this endpoint you will initiate <br> a rotating session with a specified <p @mouseenter="timeHover = true" @mouseleave="timeHover = false" style="color:orange; display:inline;cursor: pointer;">time</p> on a selected <p @mouseenter="portHover = true" @mouseleave="portHover = false" style="color:orange; display:inline;cursor: pointer;">port</p>.</p>
            <p class="api-subtitle">endpoint</p>
            /rotateIp
            <p class="api-subtitle">request type</p>
            GET
            <p class="api-subtitle">parameters</p>
           <p :class="{timeHover:timeHover}">time : the number of seconds you want to keep the ip between each rotation</p> <br>
            must be over 60 seconds. <br> 
            <p :class="{portHover:portHover}">port : the port number of the ip you wish to start a rotation session.</p>
            <p class="api-subtitle">url example</p>
            <div >
                <p>server IP : </p>
                <input v-model="serverIp" type="text" name="" id="">
                <p :class="{portHover:portHover}" >port : </p> 
                <input v-model="port" type="number" name="" id="">
                <p :class="{timeHover:timeHover}">time : </p> 
                <input v-model="time" type="number" name="" id="">
            </div>
            <p  style="margin-top: 20px;">http://{{serverIp}}:{{port}}/rotateIp</p>
            </div>
        </div>
        <div v-if="memory[2] ">
            <p class="api-title">Stop rotation</p>
            <div style="padding-left:15px">
                <p class="api-subtitle">use case</p>
            <p>By making a get request on this endpoint you will cancel <br> the rotation session you've initiated on the specified <p @mouseenter="portHover = true" @mouseleave="portHover = false" style="color
            :orange; display:inline;cursor: pointer;">port</p></p>
            <p class="api-subtitle">endpoint</p>
            /stopRotation
            <p class="api-subtitle">request type</p>
            GET
            <p class="api-subtitle">parameters</p>
            port : the port number on which you want to cancel the rotation session 
            <p class="api-subtitle">url example</p>
            <div >
                <p>server IP : </p>
                <input v-model="serverIp" type="text" name="" id="">
                <p>port : </p> 
                <input v-model="port" type="number" name="" id="">
            </div>
            <p style="margin-top: 20px;">http://{{serverIp}}:{{port}}/stopRotation</p>
            </div>
        </div><div v-if="memory[3] ">
            <p class="api-title">Check consumption</p>
            <div style="padding-left:15px">
                <p class="api-subtitle">use case</p>
            <p>by making a get request on this endpoint you will fetch <br> the amount of data consumed in megabits</p>
            <p class="api-subtitle">endpoint</p>
            /getData
            <p class="api-subtitle">request type</p>
            GET
            <p class="api-subtitle">parameters</p>
            uid : your user identification string
            <p class="api-subtitle">url example</p>
            <div >
                <p>server IP : </p>
                <input v-model="serverIp" type="text" name="" id="">
                <p>uid : </p> 
                <input v-model="userInfo.id" type="text" name="" id="">
            </div>
            <p style="margin-top: 20px;">http://{{serverIp}}:3000/{{userInfo.id}}/getData</p>
            </div>
        </div>
        </div>
    </div>
  </div>

</template>

<script>
import getUserInfo from '@/composables/getUserInfo'
import { ref, computed } from 'vue'
export default {
    setup() {
        const memory = ref([true,false,false, false])
        const test = true
        const userInfo = getUserInfo()
        const lol = (ind) => {
          memory.value.forEach( (val, index) => ind == index ? memory.value[index] = true: memory.value[index] = false)
          console.log(memory.value)
        }
        const serverIp = ref("127.0.0.1")
        const time = ref(60)
        const port = ref(8080)

        const portHover = ref(false)
        const timeHover = ref(false)
        const uid = computed(() =>{
            if(!userInfo.value.id){
                return ""
            }
            else {
                    return userInfo.value.id
            }            
        })

        return {serverIp, port,time, memory, lol, userInfo, portHover, timeHover}
    }
}
</script>

<style scoped>

.menu-selection {
    font-weight: 500;
    margin:5px;
    cursor: pointer;
}

.api-title {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}
.api-subtitle {
    margin-top: 12px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 500;
}

.api-subtitle * {
    padding-left: 5px;
}
.portHover {
    color: orange;
    transition: 0.1s;
}
.timeHover {
    color: orange;
    transition: 0.1s;
}
</style>