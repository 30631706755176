import { ref } from "vue"
import { doc } from "firebase/firestore"
import { onSnapshot } from "firebase/firestore"
import { db } from "@/firebase/config"
import { watchEffect } from "vue"

const getDocument = (collection, id) => {

  let document = ref(null)
  let error = ref(null)
  
  // register the firestore collection reference
  const postRef = doc(db, collection, id)

  const unsub = onSnapshot(postRef, doc => {
    // need to make sure the doc exists & has data
    if(doc.data()) {
      document.value = {...doc.data(), id: doc.id}
      error.value = null
    }
    else {
      error.value = 'that document does not exist'
    }
  }, err => {
    console.log(err.message)
    error.value = 'problem fetching the document'
  })

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub())
  });
  return document

}

export default getDocument