import { initializeApp } from "firebase/app";
import {getFirestore} from 'firebase/firestore'
import {getAuth} from 'firebase/auth'
import { Timestamp } from 'firebase/firestore';
import { FieldValue } from 'firebase/firestore';
import { serverTimestamp } from 'firebase/firestore';
import {getFunctions, httpsCallable} from 'firebase/functions'

const firebaseConfig = {
  apiKey: "AIzaSyAt-lpgAlXtvMhISxUIAAsj75YgdmYD_Rc",
  authDomain: "prox-151db.firebaseapp.com",
  projectId: "prox-151db",
  storageBucket: "prox-151db.appspot.com",
  messagingSenderId: "10461314149",
  appId: "1:10461314149:web:1c70280197561b1fbdda8c"
};
const firebase = initializeApp(firebaseConfig)

const db = getFirestore()
const auth = getAuth()
const timestamp = FieldValue.serverTimestamp
const functions = getFunctions(firebase, "europe-west1")
export { db, auth, timestamp, functions, httpsCallable }