<template>
    <p class="text" data-v-b902bbd7 style="margin-left:100px; margin-bottom: 70px;" >PRICE PLANS</p>
        <div style="display: flex;justify-content: center;align-items: center;">
            <PriceCard myColor="yellow" duration="Day" price="5"></PriceCard>
            <PriceCard myColor="orange" duration="Week" price="25"></PriceCard>
            <PriceCard myColor="orangered" duration="Month" price="70"></PriceCard>
            <PriceCard myColor="red" duration="Year" price="600"></PriceCard>
        </div> 
</template>
   
<script>
import PriceCard from './PriceCard.vue';
export default {
    name: "PricingSection",
    components: {
        PriceCard
    }
}
</script>

<style scoped>
</style>